<template>
  <modal class-name="w-11/12 sm:w-480 pt-14" ref="welcomeModal">
    <div class="flex justify-center">
      <icon-star />
    </div>
    <div class="mt-8 text-center">
      <h4 class="font-bold text-32 text-invest-primary tracking-base">
        Welcome {{ firstName }}!
      </h4>

      <div class="px-12 mt-8 text-xs leading-relaxed text-invest-dark">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua.
      </div>
    </div>

    <div class="mt-12 mb-5">
      <button
        class="btn btn-primary"
        type="button"
        @click="$refs.createInvestmentModal.open()"
      >
        Start Investing
      </button>
    </div>
  </modal>

  <create-investment ref="createInvestmentModal" />
</template>
<script lang="ts">
import { defineComponent, ref, onMounted, defineAsyncComponent } from 'vue';
import sessionModule from '@/modules/auth/sessionModule';
export default defineComponent({
  components: {
    CreateInvestment: defineAsyncComponent(
      () => import('@/components/investment/create-investment/Index.vue'),
    ),
  },
  setup: () => {
    const welcomeModal = ref<any>(null);
    const createInvestmentModal = ref<any>(null);

    onMounted(() => setTimeout(() => welcomeModal.value?.open(), 500));

    return {
      welcomeModal,
      createInvestmentModal,
      firstName: sessionModule()?.firstName,
    };
  },
});
</script>
