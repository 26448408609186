
import { defineComponent, ref, onMounted, defineAsyncComponent } from 'vue';
import sessionModule from '@/modules/auth/sessionModule';
export default defineComponent({
  components: {
    CreateInvestment: defineAsyncComponent(
      () => import('@/components/investment/create-investment/Index.vue'),
    ),
  },
  setup: () => {
    const welcomeModal = ref<any>(null);
    const createInvestmentModal = ref<any>(null);

    onMounted(() => setTimeout(() => welcomeModal.value?.open(), 500));

    return {
      welcomeModal,
      createInvestmentModal,
      firstName: sessionModule()?.firstName,
    };
  },
});
